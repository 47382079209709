import { CCol, CRow } from '@coreui/react-pro'
import * as Yup from 'yup'
import Wizard, { WizardStep } from 'src/components/forms/Wizard'
import { FormTextInput } from 'src/components/forms/FormTextInput'
import { FormSelectInput } from 'src/components/forms/FormSelectInput'
import { FormCheckInput } from 'src/components/forms/FormCheckInput'
import { FormLabel } from 'src/components/forms/FormLabel'
import React, { useState, useContext } from 'react'
import {
  createManufacturer,
  createBuildingContractor,
  updateBuildingContractor,
  updateManufacturer,
} from 'src/services/requests/partners'
import { useTranslation } from 'react-i18next'
import { FormSelectCountriesDisabled } from 'src/components/forms/selects/FormSelectCountriesDisabled'
import { TABTYPE, useBackofficeParams } from 'src/hooks/useBackofficeParams'
import {
  createBuildingContractorBody,
  createManufacturerBody,
} from 'src/views/pages/backoffice/createPartnerBody'
import { AuthContext, PERMISSIONS, VIEWS } from 'src/contexts/authContext'

export const PartnerForm = ({
  editMode,
  rowData,
  fetchData,
  setOffcanvasVisible,
  setDeactivateOffcanvasVisible,
  setIsLoading,
  unsavedChangesOffcanvasVisible,
  setUnsavedChangesOffcanvasVisible,
  initialValuesChanged,
  setInitialvaluesChanged,
  setExistingStepNumber,
  readOnly,
  setNewPartnerId,
}) => {
  const { partnerType } = useBackofficeParams()
  const [isDisabledByPartnerType, setIsDisabledByPartnerType] = useState(
    partnerType === TABTYPE.BUILDINGCONTRACTORS,
  )
  const { t } = useTranslation()
  const prepopulateData = editMode || readOnly
  const initialValues = {
    id: prepopulateData ? rowData?.id : undefined,
    type: prepopulateData
      ? rowData?.type
      : partnerType == TABTYPE.MANUFACTURERS
      ? 'manufacturer'
      : 'building_contractor',
    name: prepopulateData ? rowData?.name : '',
    prefix: prepopulateData ? rowData?.prefix : '',
    country: prepopulateData ? rowData?.country : 'CH',
    domain: prepopulateData ? rowData?.domain : '',
    languages: prepopulateData ? rowData?.languages : ['de'],
    vatNum: prepopulateData ? rowData?.legal_entity.vat_num : '',
    purpose: prepopulateData ? rowData?.legal_entity.purpose : '',
    nextDayDelivery: prepopulateData
      ? rowData?.partner_config?.next_day_delivery?.toString()
      : true,
    productAvailabilityDisplay: prepopulateData
      ? rowData?.partner_config?.product_availability_display
      : '',
    videoCall: prepopulateData ? rowData?.partner_config?.video_call?.toString() : true,
    hidePrices: prepopulateData ? rowData?.partner_config?.hide_prices?.toString() : true,
    percentage: prepopulateData ? rowData?.partner_config?.percentage : '',
    paymentTerms: prepopulateData ? rowData?.partner_config?.payment_terms : '',
    settlementFeeType: prepopulateData
      ? rowData?.partner_config?.settlement_fee_type
      : 'cash_collection',
    settlementFeePercentage: prepopulateData
      ? rowData?.partner_config?.settlement_fee_percentage
      : '',
    HGCPartnerId: prepopulateData ? rowData?.partner_config?.hgc_partner_id : '',
    HGCPartnerCreditId: prepopulateData ? rowData?.partner_config?.hgc_partner_credit_id : '',
    contactEmail: prepopulateData ? rowData?.partner_config?.contact_email : '',
    street: prepopulateData ? rowData?.address.street : '',
    houseNumber: prepopulateData ? rowData?.address.house_nr : '',
    addressCountry: prepopulateData ? rowData?.address.country : 'CH',
    city: prepopulateData ? rowData?.address.city : '',
    postalCode: prepopulateData ? rowData?.address.postal_code : '',
    lat: prepopulateData ? rowData?.address.lat : undefined,
    lon: prepopulateData ? rowData?.address.lon : undefined,
    billingEmail: prepopulateData ? rowData?.address.contact_email : '',
    status: prepopulateData ? rowData?.status : 'active',
    francoLimit: prepopulateData ? rowData?.partner_config?.franco_limit : '',
    leadTime: prepopulateData ? rowData?.partner_config?.lead_time : '',
    invoice_email: prepopulateData ? rowData?.partner_config?.invoice_email : '',
    credit_email: prepopulateData ? rowData?.partner_config?.credit_email : '',
    receive_credit_emails: prepopulateData
      ? rowData?.partner_config?.receive_credit_emails?.toString()
      : true,
  }
  const [internalLanguages, setInternalLanguages] = useState(initialValues.languages)
  const formSchema = {
    legalEntity: {
      [TABTYPE.MANUFACTURERS]: {
        step: 'legalEntity',
        fields: [
          'type',
          'name',
          'country',
          'vatNum',
          'HGCPartnerId',
          'HGCPartnerCreditId',
          'prefix',
          'domain',
          'purpose',
          'status',
        ],
      },
      [TABTYPE.BUILDINGCONTRACTORS]: {
        step: 'legalEntity',
        fields: [
          'type',
          'name',
          'country',
          'vatNum',
          'HGCPartnerId',
          'HGCPartnerCreditId',
          'domain',
          'purpose',
          'status',
        ],
      },
    },
    billingAddress: {
      step: 'billingAddress',
      fields: [
        'billingEmail',
        'street',
        'houseNumber',
        'city',
        'postalCode',
        'addressCountry',
        'lat',
        'lon',
      ],
    },
    configuration: {
      [TABTYPE.MANUFACTURERS]: {
        step: 'configuration',
        fields: [
          'nextDayDelivery',
          'productAvailabilityDisplay',
          'videoCall',
          'hidePrices',
          'oneStepOrder',
          'percentage',
          'paymentTerms',
          'settlementFeeType',
          'settlementFeePercentage',
          'HGCPartnerId',
          'contactEmail',
          'francoLimit',
          'leadTime',
          'invoice_email',
          'credit_email',
          'receive_credit_emails',
        ],
      },
      [TABTYPE.BUILDINGCONTRACTORS]: {
        step: 'configuration',
        fields: [
          'contactEmail',
          'videoCall',
          'paymentTerms',
          'invoice_email',
          'credit_email',
          'receive_credit_emails',
        ],
      },
    },
  }
  const validationSchema = {
    [TABTYPE.MANUFACTURERS]: Yup.object({
      type: Yup.string().required(t('Yup.validation.error.required')),
      name: Yup.string().required(t('Yup.validation.error.required')),
      country: Yup.string()
        .required(t('Yup.validation.error.required'))
        .min(2, t('Yup.validation.error.country.length'))
        .max(2, t('Yup.validation.error.country.length')),
      vatNum: Yup.string().required(t('Yup.validation.error.required')),
      HGCPartnerId: Yup.string().required(t('Yup.validation.error.required')),
      HGCPartnerCreditId: Yup.string(),
      prefix: Yup.string()
        .min(3, t('Yup.Validation.Error.Prefix.Min'))
        .required(t('Yup.validation.error.required')),
      domain: Yup.string().min(3, t('Yup.validation.error.domain.minLength')).nullable(),
      purpose: Yup.string(),
      nextDayDelivery: Yup.boolean().required(t('Yup.validation.error.required')),
      productAvailabilityDisplay: Yup.string().required(t('Yup.validation.error.required')),
      videoCall: Yup.boolean().required(t('Yup.validation.error.required')),
      hidePrices: Yup.boolean().required(t('Yup.validation.error.required')),
      percentage: Yup.number()
        .min(0, t('Yup.Validation.Error.Percentage.Min'))
        .max(100, t('Yup.Validation.Error.Percentage.Max'))
        .required(t('Yup.validation.error.required')),
      paymentTerms: Yup.number()
        .min(0, t('Yup.Validation.Error.PaymentTerms.Min'))
        .required(t('Yup.validation.error.required')),
      settlementFeeType: Yup.string().required(t('Yup.validation.error.required')),
      settlementFeePercentage: Yup.number()
        .min(0, t('Yup.Validation.Error.Percentage.Min'))
        .max(100, t('Yup.Validation.Error.Percentage.Max'))
        .required(t('Yup.validation.error.required')),
      contactEmail: Yup.string()
        .email(t('Yup.validation.error.invalid.email'))
        .required(t('Yup.validation.error.required')),
      billingEmail: Yup.string()
        .email(t('Yup.validation.error.invalid.email'))
        .required(t('Yup.validation.error.required')),
      street: Yup.string().required(t('Yup.validation.error.required')),
      houseNumber: Yup.string().nullable(),
      city: Yup.string().required(t('Yup.validation.error.required')),
      postalCode: Yup.string().required(t('Yup.validation.error.required')),
      addressCountry: Yup.string()
        .min(2, t('Yup.validation.error.country.length'))
        .max(2, t('Yup.validation.error.country.length'))
        .required(t('Yup.validation.error.required')),
      lat: Yup.number().nullable(),
      lon: Yup.number().nullable(),
      status: Yup.string(),
      languages: Yup.array().of(Yup.string()).min(1, t('Yup.validation.error.required')),
      francoLimit: Yup.number().nullable(),
      leadTime: Yup.number().nullable().integer(t('Yup.validation.error.requiredInteger')),
      invoice_email: Yup.string()
        .email(t('Yup.validation.error.invalid.email'))
        .required(t('Yup.validation.error.required')),
      credit_email: Yup.string()
        .email(t('Yup.validation.error.invalid.email'))
        .required(t('Yup.validation.error.required')),
      receive_credit_emails: Yup.boolean().required(),
    }).test('francoLimit-leadTime', function (value) {
      const { francoLimit, leadTime } = value

      const isFrancoLimitEmpty = francoLimit === null || francoLimit === undefined
      const isLeadTimeEmpty = leadTime === null || leadTime === undefined

      if (isFrancoLimitEmpty && isLeadTimeEmpty) {
        return true
      }

      if (francoLimit && leadTime) {
        return true
      }

      if (francoLimit && isLeadTimeEmpty) {
        return this.createError({
          path: 'leadTime',
          message: t('Yup.validation.error.required'),
        })
      } else if (isFrancoLimitEmpty && leadTime) {
        return this.createError({
          path: 'francoLimit',
          message: t('Yup.validation.error.required'),
        })
      }
    }),
    [TABTYPE.BUILDINGCONTRACTORS]: Yup.object({
      type: Yup.string().required(t('Yup.validation.error.required')),
      name: Yup.string().required(t('Yup.validation.error.required')),
      country: Yup.string()
        .required(t('Yup.validation.error.required'))
        .min(2, t('Yup.validation.error.country.length'))
        .max(2, t('Yup.validation.error.country.length')),
      vatNum: Yup.string().required(t('Yup.validation.error.required')),
      HGCPartnerId: Yup.string().required(t('Yup.validation.error.required')),
      domain: Yup.string().min(3, t('Yup.validation.error.domain.minLength')).nullable(),
      purpose: Yup.string(),
      videoCall: Yup.boolean().required(t('Yup.validation.error.required')),
      paymentTerms: Yup.number()
        .min(0, t('Yup.Validation.Error.PaymentTerms.Min'))
        .required(t('Yup.validation.error.required')),
      contactEmail: Yup.string()
        .email(t('Yup.validation.error.invalid.email'))
        .required(t('Yup.validation.error.required')),
      billingEmail: Yup.string()
        .email(t('Yup.validation.error.invalid.email'))
        .required(t('Yup.validation.error.required')),
      street: Yup.string().required(t('Yup.validation.error.required')),
      houseNumber: Yup.string().nullable(),
      city: Yup.string().required(t('Yup.validation.error.required')),
      postalCode: Yup.string().required(t('Yup.validation.error.required')),
      addressCountry: Yup.string()
        .min(2, t('Yup.validation.error.country.length'))
        .max(2, t('Yup.validation.error.country.length'))
        .required(t('Yup.validation.error.required')),
      lat: Yup.number().nullable(),
      lon: Yup.number().nullable(),
      status: Yup.string(),
      invoice_email: Yup.string()
        .email(t('Yup.validation.error.invalid.email'))
        .required(t('Yup.validation.error.required')),
      credit_email: Yup.string()
        .email(t('Yup.validation.error.invalid.email'))
        .required(t('Yup.validation.error.required')),
      receive_credit_emails: Yup.boolean().required(),
    }),
  }

  const auth = useContext(AuthContext)
  const viewPermissions = PERMISSIONS[auth.role][VIEWS.PARTNERS].VIEW
  const editPermission = PERMISSIONS[auth.role][VIEWS.PARTNERS].EDIT

  return (
    <Wizard
      editMode={editMode}
      readOnly={readOnly}
      initialValues={initialValues}
      setOffcanvasVisible={setOffcanvasVisible}
      setDeactivateOffcanvasVisible={setDeactivateOffcanvasVisible}
      setInitialvaluesChanged={setInitialvaluesChanged}
      initialValuesChanged={initialValuesChanged}
      unsavedChangesOffcanvasVisible={unsavedChangesOffcanvasVisible}
      setUnsavedChangesOffcanvasVisible={setUnsavedChangesOffcanvasVisible}
      validationSchema={validationSchema[partnerType]}
      setExistingStepNumber={setExistingStepNumber}
      allFormSchema={{
        legalEntity: formSchema.legalEntity[partnerType],
        billingAddress: formSchema.billingAddress,
        configuration: formSchema.configuration[partnerType],
      }}
      onSubmit={(values) => {
        return editMode
          ? updatePartnerCall(
              values,
              partnerType,
              TABTYPE,
              rowData,
              fetchData,
              setOffcanvasVisible,
              setIsLoading,
            )
          : createPartnerCall(values, fetchData, setOffcanvasVisible, setIsLoading, setNewPartnerId)
      }}
      stepsProperties={[
        { id: 'legalEntity', label: t('Backoffice.Wizard.AddPartner.Step.LegalEntity') },
        { id: 'billingAddress', label: t('Backoffice.Wizard.AddPartner.Step.BillingAddress') },
        { id: 'configuration', label: t('Backoffice.Wizard.AddPartner.Step.Configuration') },
      ]}
      identifier={'partners'}
    >
      <WizardStep>
        <p className="spartan-font">{t('Backoffice.Wizard.AddPartner.Step.LegalEntity')}</p>
        {viewPermissions?.includes('type') && (
          <FormSelectInput
            className="my-2 form-control-backoffice"
            placeholder={t('Backoffice.Wizard.AddPartner.label.typeOfPartner')}
            options={[
              { label: t('Backoffice.Wizard.AddPartner.Type.MF'), value: 'manufacturer' },
              { label: t('Backoffice.Wizard.AddPartner.Type.BC'), value: 'building_contractor' },
            ]}
            id="type"
            name="type"
            disabled={!editPermission?.includes('type') || readOnly}
            onClick={(val) => {
              if (val.target.value === 'building_contractor') {
                setIsDisabledByPartnerType(true)
              } else {
                setIsDisabledByPartnerType(false)
              }
            }}
          />
        )}
        {viewPermissions?.includes('name') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.Name')}
            type="text"
            id="name"
            name="name"
            disabled={!editPermission?.includes('name') || readOnly}
          />
        )}

        {viewPermissions?.includes('country') && (
          <FormSelectCountriesDisabled
            id="country"
            name="country"
            className="my-2 form-control-backoffice"
          />
        )}
        {viewPermissions?.includes('vatNum') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.VAT')}
            type="text"
            id="vatNum"
            name="vatNum"
            disabled={!editPermission?.includes('vatNum') || readOnly}
          />
        )}

        {viewPermissions?.includes('HGCPartnerId') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.HGCPartnerID')}
            type="text"
            id="HGCPartnerId"
            name="HGCPartnerId"
            disabled={!editPermission?.includes('HGCPartnerId') || readOnly}
          />
        )}
        {viewPermissions?.includes('HGCPartnerCreditId') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.HGCPartnerCreditID')}
            type="text"
            id="HGCPartnerCreditId"
            name="HGCPartnerCreditId"
            disabled={!editPermission?.includes('HGCPartnerCreditId') || readOnly}
          />
        )}
        {viewPermissions?.includes('prefix') && !isDisabledByPartnerType && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.Prefix')}
            type="text"
            id="prefix"
            name="prefix"
            disabled={!editPermission?.includes('prefix') || readOnly}
          />
        )}

        {viewPermissions?.includes('domain') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.Domain')}
            type="text"
            id="domain"
            name="domain"
            disabled={!editPermission?.includes('domain') || readOnly}
          />
        )}
        {viewPermissions?.includes('purpose') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.Purpose')}
            type="text"
            id="purpose"
            name="purpose"
            disabled={!editPermission?.includes('purpose') || readOnly}
          />
        )}

        {viewPermissions?.includes('status') && (
          <CCol md="12">
            <CRow>
              <FormLabel name={t('Backoffice.users.table.columns.status')} />
            </CRow>
            <CRow>
              <CCol md="4">
                <FormCheckInput
                  label={t('Backoffice.Wizard.AddUser.Label.userStatus.active')}
                  value="active"
                  type="radio"
                  id="status"
                  name="status"
                  disabled={!editPermission?.includes('status') || readOnly}
                />
              </CCol>
              <CCol md="4">
                <FormCheckInput
                  label={t('Backoffice.Wizard.AddUser.Label.userStatus.inactive')}
                  value="inactive"
                  type="radio"
                  id="status"
                  name="status"
                  disabled={!editPermission?.includes('status') || readOnly}
                />
              </CCol>
            </CRow>
          </CCol>
        )}
      </WizardStep>
      <WizardStep>
        <p className="spartan-font">{t('Backoffice.Wizard.AddPartner.Step.BillingAddress')}</p>

        {viewPermissions?.includes('billingEmail') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.BillingEmail')}
            type="email"
            id="billingEmail"
            name="billingEmail"
            disabled={!editPermission?.includes('billingEmail') || readOnly}
          />
        )}
        {viewPermissions?.includes('addressCountry') && (
          <FormSelectCountriesDisabled
            id="addressCountry"
            name="addressCountry"
            className="my-2 form-control-backoffice"
          />
        )}
        {viewPermissions?.includes('street') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.Street')}
            type="text"
            id="street"
            name="street"
            disabled={!editPermission?.includes('street') || readOnly}
          />
        )}
        {viewPermissions?.includes('houseNumber') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.HouseNr')}
            type="text"
            id="houseNumber"
            name="houseNumber"
            disabled={!editPermission?.includes('houseNumber') || readOnly}
          />
        )}
        {viewPermissions?.includes('postalCode') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.ZipCode')}
            type="text"
            id="postalCode"
            name="postalCode"
            disabled={!editPermission?.includes('postalCode') || readOnly}
          />
        )}
        {viewPermissions?.includes('city') && (
          <FormTextInput
            className="my-2"
            Città
            placeholder={t('Backoffice.Wizard.AddPartner.label.city')}
            type="text"
            id="city"
            name="city"
            disabled={!editPermission?.includes('city') || readOnly}
          />
        )}

        {/* <FormTextInput
          placeholder={t('Backoffice.Wizard.AddPartner.Lat')}
          type="number"
          id="lat"
          name="lat"
          disabled={!editPermission?.includes('') || readOnly}
        />
        <FormTextInput
          placeholder={t('Backoffice.Wizard.AddPartner.Lon')}
          type="number"
          id="lon"
          name="lon"
          disabled={!editPermission?.includes('') || readOnly}
        /> */}
      </WizardStep>
      <WizardStep>
        <p className="spartan-font">{t('Backoffice.Wizard.AddPartner.Step.Configuration')}</p>
        {viewPermissions?.includes('contactEmail') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.PrimaryEmail')}
            type="email"
            id="contactEmail"
            name="contactEmail"
            disabled={!editPermission?.includes('contactEmail') || readOnly}
          />
        )}
        {viewPermissions?.includes('invoice_email') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.label.invoiceEmail')}
            type="text"
            id="invoice_email"
            name="invoice_email"
            disabled={!editPermission?.includes('invoice_email') || readOnly}
          />
        )}
        {viewPermissions?.includes('credit_email') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.label.creditEmail')}
            type="text"
            id="credit_email"
            name="credit_email"
            disabled={!editPermission?.includes('credit_email') || readOnly}
          />
        )}
        {viewPermissions?.includes('receive_credit_emails') && (
          <CCol md="12">
            <CRow>
              <FormLabel name={t('Backoffice.Wizard.AddPartner.receiveCreditEmails')} />
            </CRow>
            <CRow>
              <CCol md="4">
                <FormCheckInput
                  label={t('Global.Verbal.Positive')}
                  inline
                  defaultChecked
                  type="radio"
                  id="receiveCreditEmails"
                  name="receive_credit_emails"
                  value="true"
                  disabled={!editPermission?.includes('receive_credit_emails') || readOnly}
                />
              </CCol>

              <CCol md="4">
                <FormCheckInput
                  label={t('Global.Verbal.Negative')}
                  inline
                  type="radio"
                  id="receiveCreditEmails"
                  name="receive_credit_emails"
                  value="false"
                  disabled={!editPermission?.includes('receive_credit_emails') || readOnly}
                />
              </CCol>
            </CRow>
          </CCol>
        )}
        {viewPermissions?.includes('nextDayDeliveryTrue' || 'nextDayDeliveryFalse') &&
          !isDisabledByPartnerType && (
            <CCol md="12">
              <CRow>
                <FormLabel name={t('Backoffice.Wizard.AddPartner.NextDayDelivery.Label')} />
              </CRow>
              <CRow>
                {viewPermissions?.includes('nextDayDeliveryTrue') && (
                  <CCol md="4">
                    <FormCheckInput
                      className="my-2"
                      label={t('Global.Verbal.Positive')}
                      inline
                      defaultChecked
                      type="radio"
                      id="nextDayDeliveryTrue"
                      name="nextDayDelivery"
                      value="true"
                      disabled={!editPermission?.includes('nextDayDeliveryTrue') || readOnly}
                    />
                  </CCol>
                )}
                {viewPermissions?.includes('nextDayDeliveryFalse') && (
                  <CCol md="4">
                    <FormCheckInput
                      className="my-2"
                      label={t('Global.Verbal.Negative')}
                      inline
                      type="radio"
                      id="nextDayDeliveryFalse"
                      name="nextDayDelivery"
                      value="false"
                      disabled={!editPermission?.includes('nextDayDeliveryFalse') || readOnly}
                    />
                  </CCol>
                )}
              </CRow>
            </CCol>
          )}
        {/* <CCol md="12">
          <CRow>
            <FormLabel name={t('Backoffice.Wizard.AddPartner.UsesVideoCall')} />
          </CRow>
          <CRow>
            <CCol md="4">
              <FormCheckInput
                label={t('Global.Verbal.Positive')}
                inline
                defaultChecked
                type="radio"
                id="videoCallTrue"
                name="videoCall"
                value="true"
                disabled={!editPermission?.includes('') || readOnly}
              />
            </CCol>
            <CCol md="4">
              <FormCheckInput
                label={t('Global.Verbal.Negative')}
                inline
                type="radio"
                id="videoCallFalse"
                name="videoCall"
                value="false"
                disabled={!editPermission?.includes('') || readOnly}
              />
            </CCol>
          </CRow>
        </CCol> */}

        {viewPermissions?.includes('paymentTerms') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.PaymentDeadline')}
            type="number"
            id="paymentTerms"
            name="paymentTerms"
            disabled={!editPermission?.includes('paymentTerms') || readOnly}
          />
        )}

        {!isDisabledByPartnerType && (
          <>
            {viewPermissions?.includes('productAvailabilityDisplay') && (
              <FormTextInput
                placeholder={t('Backoffice.Wizard.AddPartner.AvailabilityDisplay')}
                type="text"
                id="productAvailabilityDisplay"
                name="productAvailabilityDisplay"
                disabled={!editPermission?.includes('productAvailabilityDisplay') || readOnly}
              />
            )}
            {viewPermissions?.includes('settlementFeeType') && (
              <FormSelectInput
                placeholder={t('Backoffice.Wizard.AddPartner.SettlementFeeType')}
                className="my-2 form-control-backoffice"
                options={[
                  { label: 'Cash Collection', value: 'cash_collection' },
                  { label: 'Factoring', value: 'factoring' },
                ]}
                id="settlementFeeType"
                name="settlementFeeType"
                disabled={!editPermission?.includes('settlementFeeType') || readOnly}
              />
            )}
            {viewPermissions?.includes('settlementFeePercentage') && (
              <FormTextInput
                placeholder={t('Backoffice.Wizard.AddPartner.SettlementFeePerc')}
                type="number"
                id="settlementFeePercentage"
                name="settlementFeePercentage"
                disabled={!editPermission?.includes('settlementFeePercentage') || readOnly}
              />
            )}
            {viewPermissions?.includes('percentage') && (
              <FormTextInput
                placeholder={t('Backoffice.Wizard.AddPartner.PlatformFeePerc')}
                type="number"
                id="percentage"
                name="percentage"
                disabled={!editPermission?.includes('percentage') || readOnly}
              />
            )}
            {viewPermissions?.includes('hidePricesTrue' || 'hidePricesFalse') && (
              <CCol md="12">
                <CRow>
                  <FormLabel name={t('Backoffice.Wizard.AddPartner.HidePrices')} />
                </CRow>
                <CRow>
                  {viewPermissions?.includes('hidePricesTrue') && (
                    <CCol md="4">
                      <FormCheckInput
                        label={t('Global.Verbal.Positive')}
                        inline
                        defaultChecked
                        type="radio"
                        id="hidePricesTrue"
                        name="hidePrices"
                        value="true"
                        disabled={!editPermission?.includes('hidePricesTrue') || readOnly}
                      />
                    </CCol>
                  )}
                  {viewPermissions?.includes('hidePricesFalse') && (
                    <CCol md="4">
                      <FormCheckInput
                        label={t('Global.Verbal.Negative')}
                        inline
                        type="radio"
                        id="hidePricesFalse"
                        name="hidePrices"
                        value="false"
                        disabled={!editPermission?.includes('hidePricesFalse') || readOnly}
                      />
                    </CCol>
                  )}
                </CRow>
              </CCol>
            )}

            {viewPermissions?.includes('francoLimit') && (
              <FormTextInput
                placeholder={t('Backoffice.Wizard.AddPartner.FrancoLimit')}
                type="number"
                id="francoLimit"
                name="francoLimit"
                disabled={!editPermission?.includes('francoLimit') || readOnly}
              />
            )}

            {viewPermissions?.includes('leadTime') && (
              <FormTextInput
                placeholder={t('Backoffice.Wizard.AddPartner.LeadTime')}
                type="number"
                id="leadTime"
                name="leadTime"
                disabled={!editPermission?.includes('leadTime') || readOnly}
              />
            )}

            {viewPermissions?.includes(
              'languagesGerman' || 'languagesFrench' || 'languagesEnglish' || 'languagesItalian',
            ) && (
              <CCol md="12">
                <CRow>
                  <FormLabel name={t('Backoffice.Wizard.AddPartner.AvailableLanguages')} />
                </CRow>
                <CRow>
                  <CCol md="4">
                    <FormCheckInput
                      label={t('Global.Language.German')}
                      id="languagesGerman"
                      name="languages"
                      value="de"
                      checked={internalLanguages.includes('de')}
                      onClick={() => {
                        if (internalLanguages.includes('de')) {
                          setInternalLanguages(internalLanguages.filter((lang) => lang !== 'de'))
                        } else {
                          setInternalLanguages([...internalLanguages, 'de'])
                        }
                      }}
                      disabled={!editPermission?.includes('languagesGerman') || readOnly}
                    />
                  </CCol>
                  <CCol md="4">
                    <FormCheckInput
                      label={t('Global.Language.French')}
                      id="languagesFrench"
                      name="languages"
                      value="fr"
                      checked={internalLanguages.includes('fr')}
                      onClick={() => {
                        if (internalLanguages.includes('fr')) {
                          setInternalLanguages(internalLanguages.filter((lang) => lang !== 'fr'))
                        } else {
                          setInternalLanguages([...internalLanguages, 'fr'])
                        }
                      }}
                      disabled={!editPermission?.includes('languagesFrench') || readOnly}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol md="4">
                    <FormCheckInput
                      label={t('Global.Language.English')}
                      id="languagesEnglish"
                      name="languages"
                      value="en"
                      checked={internalLanguages.includes('en')}
                      onClick={() => {
                        if (internalLanguages.includes('en')) {
                          setInternalLanguages(internalLanguages.filter((lang) => lang !== 'en'))
                        } else {
                          setInternalLanguages([...internalLanguages, 'en'])
                        }
                      }}
                      disabled={!editPermission?.includes('languagesEnglish') || readOnly}
                    />
                  </CCol>
                  <CCol md="4">
                    <FormCheckInput
                      label={t('Global.Language.Italian')}
                      id="languagesItalian"
                      name="languages"
                      value="it"
                      checked={internalLanguages.includes('it')}
                      onClick={() => {
                        if (internalLanguages.includes('it')) {
                          setInternalLanguages(internalLanguages.filter((lang) => lang !== 'it'))
                        } else {
                          setInternalLanguages([...internalLanguages, 'it'])
                        }
                      }}
                      disabled={!editPermission?.includes('languagesItalian') || readOnly}
                    />
                  </CCol>
                </CRow>
              </CCol>
            )}
          </>
        )}
      </WizardStep>
    </Wizard>
  )
}

const createPartnerCall = async (
  input,
  fetchData,
  setOffcanvasVisible,
  setIsLoading,
  setNewPartnerId,
) => {
  setIsLoading(true)
  let body = {}

  if (input.type === 'building_contractor') {
    body = {
      type: 'building_contractor',
      name: input.name,
      country: input.country,
      domain: input.domain || undefined,
      status: input.status || 'active',
      legal_entity: {
        vat_num: input.vatNum,
        purpose: input.purpose,
        uid: '',
      },
      partner_config: {
        video_call: input.videoCall === 'true',
        contact_email: input.contactEmail,
        payment_terms: input.paymentTerms,
        hgc_partner_id: input.HGCPartnerId,
        hgc_partner_credit_id: input.HGCPartnerCreditId,
        invoice_email: input.invoice_email,
        credit_email: input.credit_email,
        receive_credit_emails: input.receive_credit_emails === 'true' ? true : false,
      },
      address: {
        street: input.street,
        postal_code: input.postalCode,
        house_nr: input.houseNumber || undefined,
        city: input.city,
        country: input.addressCountry,
        contact_email: input.billingEmail,
        lon: input.lat || undefined,
        lat: input.lon || undefined,
      },
    }
    createBuildingContractor(JSON.stringify(body))
      .then((response) => {
        fetchData()
        setNewPartnerId(input.name)
        setOffcanvasVisible(false)
        setIsLoading(false)
      })
      .catch((exception) => {
        setIsLoading(false)
      })
  } else {
    body = {
      type: 'manufacturer',
      name: input.name,
      country: input.country,
      prefix: input.prefix,
      domain: input.domain || undefined,
      languages: input.languages,
      status: input.status || 'active',
      legal_entity: {
        vat_num: input.vatNum,
        purpose: input.purpose,
        uid: '',
      },
      partner_config: {
        next_day_delivery: input.nextDayDelivery === 'true',
        product_availability_display: input.productAvailabilityDisplay,
        video_call: input.videoCall === 'true',
        percentage: input.percentage,
        contact_email: input.contactEmail,
        payment_terms: input.paymentTerms,
        settlement_fee_type: input.settlementFeeType,
        settlement_fee_percentage: input.settlementFeePercentage,
        hgc_partner_id: input.HGCPartnerId,
        hgc_partner_credit_id: input.HGCPartnerCreditId,
        hide_prices: input.hidePrices === 'true',
        franco_limit: input.francoLimit || undefined,
        lead_time: input.leadTime || undefined,
        invoice_email: input.invoice_email,
        credit_email: input.credit_email,
        receive_credit_emails: input.receive_credit_emails === 'true' ? true : false,
      },
      address: {
        street: input.street,
        postal_code: input.postalCode,
        house_nr: input.houseNumber || undefined,
        city: input.city,
        country: input.addressCountry,
        contact_email: input.billingEmail,
        lon: input.lat || undefined,
        lat: input.lon || undefined,
      },
    }
    try {
      await createManufacturer(JSON.stringify(body))
      await fetchData()
      setNewPartnerId(input.name)
      setOffcanvasVisible(false)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }
}

const updatePartnerCall = async (
  values,
  partnerType,
  TABTYPE,
  rowData,
  fetchData,
  setEditOffcanvasVisible,
  setIsLoading,
) => {
  setIsLoading(true)
  const body =
    partnerType === TABTYPE.MANUFACTURERS
      ? { ...createManufacturerBody(values) }
      : { ...createBuildingContractorBody(values) }
  try {
    partnerType === TABTYPE.MANUFACTURERS
      ? await updateManufacturer(rowData.id, JSON.stringify(body))
      : await updateBuildingContractor(rowData.id, JSON.stringify(body))
    await fetchData()
    setEditOffcanvasVisible(false)
  } catch (error) {
    setIsLoading(false)
  }
  setIsLoading(false)
}
