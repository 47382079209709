import { FieldArray, useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormTextInput } from '../FormTextInput'
import { FormAddAnotherButton } from './FormAddAnotherButton'
import { FormDeleteFieldButton } from './FormDeleteFieldButton'
import { FormSelectCountriesDisabled } from './FormSelectCountriesDisabled'

export const addressValues = {
  country: 'CH',
  street: '',
  houseNumber: '',
  city: '',
  postalCode: '',
  lat: '',
  lon: '',
}

export default function FormSelectMultipleAddresses({
  readOnly,
  editMode,
  setAddressIdsToDelete,
  setInitialvaluesChanged,
  countries,
}) {
  const { t } = useTranslation()
  const {
    values: { addresses },
  } = useFormikContext()

  return (
    <>
      <FieldArray
        name="addresses"
        render={({ push, remove }) => (
          <>
            {addresses.map((address, index) => (
              <div
                key={index}
                className={`${addresses.length - 1 !== index ? 'mb-5' : ''} position-relative`}
              >
                {addresses.length > 1 && !readOnly && (
                  <FormDeleteFieldButton
                    handleClick={() => {
                      if (editMode)
                        setAddressIdsToDelete((prevIds) => [...prevIds, addresses[index].id])
                      remove(index)
                      setInitialvaluesChanged(true)
                    }}
                    index={index}
                  />
                )}
                <p className="spartan-font">
                  {addresses.length === 1
                    ? t('Backoffice.Wizard.AddProject.steps.second')
                    : `${t('Backoffice.Wizard.AddProject.steps.second')} (${index + 1})`}
                </p>
                <div>
                  <FormSelectCountriesDisabled
                    id={`addresses.${index}.country`}
                    name={`addresses.${index}.country`}
                    className="my-2 form-control-backoffice"
                    countries={countries}
                  />
                  {addresses.length > 1 && !readOnly && (
                    <FormDeleteFieldButton
                      handleClick={() => {
                        if (editMode)
                          setAddressIdsToDelete((prevIds) => [...prevIds, addresses[index].id])
                        remove(index)
                        setInitialvaluesChanged(true)
                      }}
                      index={index}
                    />
                  )}
                </div>
                <FormTextInput
                  placeholder={t('Backoffice.Wizard.AddProject.label.street')}
                  type="text"
                  id={`addresses.${index}.street`}
                  name={`addresses.${index}.street`}
                  disabled={readOnly}
                />
                <FormTextInput
                  placeholder={t('Backoffice.Wizard.AddProject.label.houseNumber')}
                  type="text"
                  id={`addresses.${index}.houseNumber`}
                  name={`addresses.${index}.houseNumber`}
                  disabled={readOnly}
                />
                <FormTextInput
                  placeholder={t('Backoffice.Wizard.AddProject.label.postalCode')}
                  type="text"
                  id={`addresses.${index}.postalCode`}
                  name={`addresses.${index}.postalCode`}
                  disabled={readOnly}
                />
                <FormTextInput
                  placeholder={t('Backoffice.Wizard.AddProject.label.city')}
                  type="text"
                  id={`addresses.${index}.city`}
                  name={`addresses.${index}.city`}
                  disabled={readOnly}
                />

                <FormTextInput
                  placeholder={t('Backoffice.Wizard.AddProject.label.latitude')}
                  type="text"
                  id={`addresses.${index}.lat`}
                  name={`addresses.${index}.lat`}
                  disabled={readOnly}
                />
                <FormTextInput
                  placeholder={t('Backoffice.Wizard.AddProject.label.longitude')}
                  type="text"
                  id={`addresses.${index}.lon`}
                  name={`addresses.${index}.lon`}
                  disabled={readOnly}
                />
              </div>
            ))}
            {!readOnly && <FormAddAnotherButton push={push} values={addressValues} />}
          </>
        )}
      />
    </>
  )
}
