import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react-pro'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { languages } from './languages'
import { SelectedLanguage } from './SelectedLanguage'

const SelectLanguage = () => {
  const { i18n, t } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)
  const handleClick = (lang) => {
    setSelectedLanguage(lang)
    i18n.changeLanguage(lang)
  }
  return (
    <CDropdown variant="nav-item" className="select-language-item">
      <CDropdownToggle
        color="secondary"
        size="sm"
        caret={false}
        className="d-flex align-items-center selected-language"
      >
        <SelectedLanguage lang={selectedLanguage} />
      </CDropdownToggle>
      <CDropdownMenu>
        {languages.map((language) => (
          <CDropdownItem
            key={language.name}
            component="button"
            className="d-flex align-items-center"
            onClick={() => handleClick(language.nameShort)}
          >
            <span>{t(`Header.Navigation.Label.${language.name}`)}</span>
          </CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
  )
}

export default SelectLanguage
