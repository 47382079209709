import React, { useState, useEffect } from 'react'
import {
  getProjectMaterialView,
  getBuildingProject,
  syncProductChangesFromProjectMaterialView,
} from 'src/services/requests/projectMaterialView'

export const ProjectMaterialViewContext = React.createContext({})

export const ProjectMaterialViewContextWrapper = (props) => {
  const [pmvsForPreview, setPmvsForPreview] = useState(null)
  const [pmvData, _setPmvData] = useState(null)
  const [structuredPMVData, setStructuredPMVData] = useState({})
  const [pmvManufacturer, setPmvManufacturer] = useState('')
  const [pmvBuildingContractor, setPmvBuildingContractor] = useState('')
  const [pmvBranchName, setPmvBranchName] = useState('')
  const [pmvProjectName, setPmvProjectName] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [pmvid, setPmvId] = useState(null)
  const [deliveryAddresses, setDeliveryAddresses] = useState()
  const [projectMaterialPreviewVisible, setProjectMaterialPreviewVisible] = useState(false)
  const [productChanges, setProductChanges] = useState(null)
  const [aMaterialChanged, setAMaterialChanged] = useState(false)
  const [materialPositions, setMaterialPositions] = useState([])

  const fetchPMV = async (queryParam, optionalPmvId, pmvState = 'new_items') => {
    setStructuredPMVData({})
    return new Promise(function (resolve, reject) {
      let finalPmvId = optionalPmvId ? optionalPmvId : pmvid
      if (!finalPmvId) {
        let message = `no pmvid available to fetch pmv in state ${queryParam}`
        reject(message)
        return
      }
      setPmvId(finalPmvId)
      getProjectMaterialView(finalPmvId, queryParam)
        .then((response) => {
          getBuildingProject(response.data.contextId)
            .then((deliveriesResponse) => {
              const arrayOfIdsWithChanges = response.data.products
                .filter(
                  (obj) =>
                    obj.state === 'in_negotiation_mf_sent' ||
                    obj.state === 'in_negotiation_bc_sent',
                )
                .map((obj) => obj.id)
              if (queryParam === 'inNegotiation' && arrayOfIdsWithChanges.length > 0) {
                const body = {
                  products: arrayOfIdsWithChanges,
                }
                syncProductChangesFromProjectMaterialView(body).then((changes) => {
                  setProductChanges(changes.data)
                })
              }
              setPmvData(response.data)
              setStructuredPMVData((prev) => {
                return {
                  ...prev,
                  [pmvState]: response.data,
                }
              })
              setPmvManufacturer(response.data.manufacturerName)
              setPmvBuildingContractor(response.data.buildingContractorName)
              setPmvBranchName(response.data.branchName)
              setPmvProjectName(response.data.buildingProjectName)
              setDeliveryAddresses(deliveriesResponse.data.addresses)
              resolve(response)
            })
            .catch((exception) => {
              setPmvData(null)
              reject(exception)
            })
            .finally(() => {
              setIsLoading(false)
            })
        })
        .catch((exception) => {
          setPmvData(null)
          setIsLoading(false)
          reject(exception)
        })
    })
  }

  const sortDeliveriesByDate = (pmv) => {
    pmv?.products?.forEach((product) => {
      product.deliveries.sort((a, b) => {
        if (a.delivery_date === null) {
          return 1
        } else if (b.delivery_date === null) {
          return -1
        } else {
          return new Date(a.delivery_date) - new Date(b.delivery_date)
        }
      })
    })
    return { ...pmv }
  }

  const setPmvData = (pmv) => {
    _setPmvData(sortDeliveriesByDate(pmv))
  }

  return (
    <>
      <ProjectMaterialViewContext.Provider
        value={{
          setPmvId,
          pmvid,
          isLoading,
          setIsLoading,
          pmvData,
          structuredPMVData,
          setPmvData,
          pmvManufacturer,
          pmvBuildingContractor,
          pmvBranchName,
          pmvProjectName,
          setPmvProjectName,
          pmvsForPreview,
          setPmvsForPreview,
          projectMaterialPreviewVisible,
          setProjectMaterialPreviewVisible,
          fetchPMV,
          deliveryAddresses,
          setDeliveryAddresses,
          productChanges,
          aMaterialChanged,
          setAMaterialChanged,
          materialPositions,
          setMaterialPositions,
        }}
      >
        {props.children}
      </ProjectMaterialViewContext.Provider>
    </>
  )
}
