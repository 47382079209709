import React, { Component } from 'react'
import { RouteOptions } from './components/RouteOptions'
import { KeyBindProvider } from 'react-keybinds'
import { toggleStrapiEventEmitter } from './i18n-backend'

import './scss/style.scss'
import LogContextWrapper from './contexts/logContext'
import ServiceContextWrapper from './contexts/serviceContext'
import WebSocketContextWrapper from './contexts/websocketContext'
import GlobalVariablesContextWrapper from './contexts/globalVariablesContext'
import CategoriesContextWrapper from './contexts/categoriesContext'
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-K7SLWVHH',
}
if (process.env.REACT_APP_STAGE === 'prod') {
  TagManager.initialize(tagManagerArgs)
}

const GLOBAL_COMMANDS = [
  {
    keys: {
      Mac: ['Control', 'Shift', 'S'],
      Windows: ['Control', 'Shift', 'S'],
    },
    label: 'show strapi translation keys',
    callback: () => {
      toggleStrapiEventEmitter.emit('toggle-strapi')
    },
  },
]

class App extends Component {
  render() {
    return (
      <>
        <Helmet>
          <meta httpEquiv="Pragma" content="no-cache" />
          <meta httpEquiv="Expires" content="0" />
          <title>cubotoo</title>
        </Helmet>
        <KeyBindProvider shortcuts={GLOBAL_COMMANDS}>
          <LogContextWrapper>
            <ServiceContextWrapper>
              <WebSocketContextWrapper>
                <GlobalVariablesContextWrapper>
                  <CategoriesContextWrapper>
                    <RouteOptions />
                  </CategoriesContextWrapper>
                </GlobalVariablesContextWrapper>
              </WebSocketContextWrapper>
            </ServiceContextWrapper>
          </LogContextWrapper>
        </KeyBindProvider>
      </>
    )
  }
}

export default App
