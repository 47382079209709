import { CCol, CFormFeedback, CRow } from '@coreui/react-pro'
import * as Yup from 'yup'
import Wizard, { WizardStep } from 'src/components/forms/Wizard'
import { FormTextInput } from 'src/components/forms/FormTextInput'
import { FormSelectInput } from 'src/components/forms/FormSelectInput'
import { FormCheckInput } from 'src/components/forms/FormCheckInput'
import { FormLabel } from 'src/components/forms/FormLabel'
import { createProject, updateProject } from 'src/services/requests/projects'
import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import FormSelectMultipleAddresses, {
  addressValues,
} from 'src/components/forms/selects/FormSelectMultipleAddresses'
import FormSelectMultipleContacts, {
  contactValues,
} from 'src/components/forms/selects/FormSelectMultipleContacts'
import FormSelectMultiplePartners from 'src/components/forms/selects/FormSelectMultiplePartners'
import FormSelectMultipleBranches from 'src/components/forms/selects/FormSelectMultipleBranches'
import { UserDataContext } from 'src/contexts/userDataContext'
import { AuthContext, ROLE } from 'src/contexts/authContext'

const Validator = require('jsonschema').Validator
const validator = new Validator()

export const ProjectForm = ({
  editMode = false,
  rowData,
  fetchData = () => {},
  setOffcanvasVisible = () => {},
  setDeactivateOffcanvasVisible = () => {},
  setIsLoading = () => {},
  unsavedChangesOffcanvasVisible = () => {},
  setUnsavedChangesOffcanvasVisible = () => {},
  initialValuesChanged = false,
  setInitialvaluesChanged = () => {},
  readOnly = false,
  partners = [],
  existingStepNumber = 0,
  setExistingStepNumber = () => {},
  branches = [],
  countries,
  partner,
  branch,
  partnerSelectionDisabled = false,
  setVisible = () => {},
  setPmvModalVisible = () => {},
  refreshProjects = () => {},
  setNewPartnerId = () => {},
  isLoading = false,
}) => {
  const { t } = useTranslation()
  const prepopulateData = editMode || readOnly
  const [addressIdsToDelete, setAddressIdsToDelete] = useState([])
  const restrictedUser = partners.length === 0
  const { selectedBranch, setSelectedBranch } = useContext(UserDataContext)
  const { role } = useContext(AuthContext)
  let final_branches = []
  let final_partners = []
  let final_contact_branches = []
  let final_contact_partners = []

  if (prepopulateData) {
    const branchIdsSet = new Set(branches.map((br) => br.id))
    const partnerIdsSet = new Set(partners.map((pr) => pr.id))
    const mergedSet = new Set([...partnerIdsSet, ...branchIdsSet])

    //user only has access to filtered contacts
    let filteredContacts = rowData.contacts
    if (role !== ROLE.MANUFACTURER_ADMIN) {
      filteredContacts = rowData.contacts.filter(
        (contact) => contact.bc_partner_id == null || mergedSet.has(contact.bc_partner_id),
      )
    }
    //maintaining private contacts for including later in the final payload
    const privateContacts = rowData.contacts
      .filter((contact) => contact?.bc_partner_id && !mergedSet.has(contact.bc_partner_id))
      .map((contact) => ({
        firstname: contact.firstname,
        lastname: contact.lastname,
        email: contact.email,
        phone: contact.phone,
        bc_partner_id: contact.bc_partner_id || undefined,
        description: contact.description || undefined,
      }))

    rowData = {
      ...rowData,
      contacts: filteredContacts,
      privateContacts,
    }
    for (let partnerID of rowData.partner_ids) {
      let selectedPartner = partners?.find((partner) => partner.id == partnerID)
      if (!selectedPartner) {
        let parent_id = branches?.find((branch) => branch?.id == partnerID)?.parent_id
        selectedPartner = partners?.find((partner) => partner?.id == parent_id)?.id
        final_partners.push(selectedPartner ? selectedPartner : null)
        final_branches.push(partnerID ? partnerID : null)
      } else {
        final_partners.push(partnerID ? partnerID : null)
        final_branches.push(partnerID ? partnerID : null)
      }
    }
    for (let contact of rowData.contacts) {
      let selectedPartner = partners?.find((partner) => partner.id == contact.bc_partner_id)
      if (!selectedPartner) {
        let parent_id = branches?.find((branch) => branch?.id == contact.bc_partner_id)?.parent_id
        selectedPartner = partners?.find((partner) => partner?.id == parent_id)?.id
        final_contact_partners.push(selectedPartner ? selectedPartner : '')
        final_contact_branches.push(contact.bc_partner_id ? contact.bc_partner_id : '')
      } else {
        final_contact_partners.push(contact.bc_partner_id ? contact.bc_partner_id : '')
        final_contact_branches.push(contact.bc_partner_id ? contact.bc_partner_id : '')
      }
    }
  }

  const initialValues = {
    id: prepopulateData ? rowData?.id : undefined,
    partners: partner ? [partner.id] : prepopulateData ? final_partners : [''],
    branches: branch ? [branch.id] : prepopulateData ? final_branches : [''],
    contact_partners: partner ? [partner.id] : prepopulateData ? final_contact_partners : [''],
    contact_branches: branch ? [branch.id] : prepopulateData ? final_contact_branches : [''],
    projectName: prepopulateData ? rowData.name : '',
    status: prepopulateData ? rowData?.status : 'active',
    addresses: prepopulateData
      ? [
          ...rowData.addresses.map((address) => ({
            id: address.id || undefined,
            street: address.street,
            postalCode: address.postal_code,
            houseNumber: address.house_nr,
            city: address.city,
            country: address.country,
            lat: address.lat || '',
            lon: address.lon || '',
          })),
        ]
      : [
          {
            ...addressValues,
          },
        ],

    contacts: prepopulateData
      ? [
          ...rowData.contacts.map((contact) => {
            return {
              firstName: contact.firstname,
              lastName: contact.lastname,
              email: contact.email || null,
              phoneNumber: contact.phone || null,
              contactType: contact.bc_partner_id ? 'private' : 'public',
              description: contact.description || '',
            }
          }),
        ]
      : [{ ...contactValues }],
  }
  const handleSubmit = async (values) => {
    setIsLoading(true)
    let branches = values.branches
    branches = [...new Set(branches)].filter((branch) => branch !== '')
    let contact_branches = values.contact_branches

    let body = {
      name: values.projectName,
      status: values.status || 'active',
      addresses: values.addresses.map((address) => {
        return {
          id: address.id || undefined,
          street: address.street,
          postal_code: address.postalCode,
          house_nr: editMode ? address.houseNumber || null : address.houseNumber || undefined,
          city: address.city,
          country: address.country,
          lon: Number(address.lon) || undefined,
          lat: Number(address.lat) || undefined,
        }
      }),
      contacts: values.contacts.map((contact, index) => {
        return {
          firstname: contact.firstName || undefined,
          lastname: contact.lastName || undefined,
          email: contact.email || undefined,
          phone: contact.phoneNumber || undefined,
          bc_partner_id: contact.contactType === 'private' ? contact_branches[index] : undefined,
          description: contact.description || undefined,
        }
      }),
      partner_ids: branches.length ? branches : [],
    }

    //merging the privateContacts with the final submit payload
    try {
      if (editMode) {
        body.address_ids_to_delete = addressIdsToDelete.length ? addressIdsToDelete : undefined
        body = {
          ...body,
          contacts: [...body.contacts, ...rowData.privateContacts],
        }
        await updateProject(rowData.id, JSON.stringify(body))
      } else {
        setNewPartnerId(values.projectName)
        const project = await createProject(JSON.stringify(body))
        refreshProjects(project?.data)
        setVisible(false)
        setPmvModalVisible(true)
      }
      await fetchData()
      setSelectedBranch(selectedBranch)
      setOffcanvasVisible(false)
    } catch (error) {
      setIsLoading(false)
    }
    setIsLoading(false)
  }
  const formSchema = {
    first: {
      step: 'first',
      fields: [
        'projectName',
        'addresses',
        'country',
        'street',
        'houseNumber',
        'postalCode',
        'city',
        'lat',
        'lon',
        'status',
      ],
    },
    second: {
      step: 'second',
      fields: [
        'contacts',
        'firstName',
        'lastName',
        'email',
        'phoneNumber',
        'description',
        'contact_partners',
        'contact_branches',
      ],
    },
    third: {
      step: 'third',
      fields: ['partners', 'branches'],
    },
  }
  const validationSchema = Yup.object().shape({
    projectName: Yup.string().required(t('Yup.validation.error.required')),
    status: Yup.string(),
    addresses: Yup.array().of(
      Yup.object().shape({
        country: Yup.string()
          .required(t('Yup.validation.error.required'))
          .min(2, t('Yup.validation.error.country.length'))
          .max(2, t('Yup.validation.error.country.length')),
        street: Yup.string().required(t('Yup.validation.error.required')),
        houseNumber: Yup.string().nullable(),
        city: Yup.string().required(t('Yup.validation.error.required')),
        postalCode: Yup.string().required(t('Yup.validation.error.required')),
        lat: Yup.number().typeError(t('Yup.validation.error.invalid.lat')).nullable(),
        lon: Yup.number().typeError(t('Yup.validation.error.invalid.lon')).nullable(),
      }),
    ),
    contacts: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string()
          .nullable()
          .test('atLeastOne', null, function () {
            const { firstName, lastName, email, phoneNumber, description } = this.parent

            if (!(firstName || lastName || email || phoneNumber || description)) {
              return this.createError({
                path: this.path,
                message: t('Yup.validation.error.AtleastOneRequired'),
              })
            }

            return true
          }),
        lastName: Yup.string()
          .nullable()
          .test('atLeastOne', null, function () {
            const { firstName, lastName, email, phoneNumber, description } = this.parent

            if (!(firstName || lastName || email || phoneNumber || description)) {
              return this.createError({
                path: this.path,
                message: t('Yup.validation.error.AtleastOneRequired'),
              })
            }

            return true
          }),
        email: Yup.string()
          .email(t('Yup.validation.error.invalid.email'))
          .nullable()
          .test('atLeastOne', null, function () {
            const { firstName, lastName, email, phoneNumber, description } = this.parent

            if (!(firstName || lastName || email || phoneNumber || description)) {
              return this.createError({
                path: this.path,
                message: t('Yup.validation.error.AtleastOneRequired'),
              })
            }

            return true
          }),
        phoneNumber: Yup.string()
          .nullable()
          .test('atLeastOne', null, function () {
            const { firstName, lastName, email, phoneNumber, description } = this.parent

            if (!(firstName || lastName || email || phoneNumber || description)) {
              return this.createError({
                path: this.path,
                message: t('Yup.validation.error.AtleastOneRequired'),
              })
            }

            return true
          }),
        description: Yup.string()
          .nullable()
          .test('atLeastOne', null, function () {
            const { firstName, lastName, email, phoneNumber, description } = this.parent

            if (!(firstName || lastName || email || phoneNumber || description)) {
              return this.createError({
                path: this.path,
                message: t('Yup.validation.error.AtleastOneRequired'),
              })
            }

            return true
          }),
        bc_partner_id: Yup.string().nullable(),
      }),
    ),
    partners: Yup.array(),
    branches: Yup.array().of(Yup.string()),
    customValidation: Yup.mixed().test('partnerAndBranchRequired', null, function (value) {
      const { partners, branches } = this.parent
      if (restrictedUser || (partners.length == 1 && partners[0] === undefined)) {
        return true
      } else if (partners && partners.length > 0) {
        for (let i = 0; i < partners.length; i++) {
          if (!branches || !branches[i]) {
            return this.createError({
              message: t('Yup.validation.error.required'),
              path: `branches.${i}`,
            })
          }
        }
      }
      return true
    }),
    contact_partners: Yup.array().of(Yup.string()),
    contact_branches: Yup.array().of(Yup.string()),
    contactPartnerValidation: Yup.mixed().test(
      'customerPartnerAndBranchRequired',
      null,
      function (value) {
        const { contact_partners, contacts } = this.parent
        if (restrictedUser) return true
        for (let i = 0; i <= contacts.length; i++) {
          if (contacts[i]?.contactType == 'private' && !contact_partners[i]) {
            return this.createError({
              message: t('Yup.validation.error.required'),
              path: `contact_partners.${i}`,
            })
          }
        }
        return true
      },
    ),
    contactBranchValidation: Yup.mixed().test(
      'customerPartnerAndBranchRequired',
      null,
      function (value) {
        const { contact_branches, contacts } = this.parent
        for (let i = 0; i <= contacts.length; i++) {
          if (contacts[i]?.contactType == 'private' && !contact_branches[i]) {
            return this.createError({
              message: t('Yup.validation.error.required'),
              path: `contact_branches.${i}`,
            })
          }
        }
        return true
      },
    ),
  })

  return (
    <Wizard
      editMode={editMode}
      readOnly={readOnly}
      initialValues={initialValues}
      setOffcanvasVisible={setOffcanvasVisible}
      setDeactivateOffcanvasVisible={setDeactivateOffcanvasVisible}
      setInitialvaluesChanged={setInitialvaluesChanged}
      initialValuesChanged={initialValuesChanged}
      unsavedChangesOffcanvasVisible={unsavedChangesOffcanvasVisible}
      setUnsavedChangesOffcanvasVisible={setUnsavedChangesOffcanvasVisible}
      validationSchema={validationSchema}
      existingStepNumber={existingStepNumber}
      setExistingStepNumber={setExistingStepNumber}
      allFormSchema={{
        first: formSchema.first,
        second: formSchema.second,
        third: formSchema.third,
      }}
      onSubmit={(values) => handleSubmit(values)}
      stepsProperties={[
        { id: 'first', label: t('Backoffice.Wizard.AddProject.steps.first') },
        { id: 'second', label: t('Backoffice.Wizard.AddProject.steps.third') },
        { id: 'third', label: t('Backoffice.branches.table.columns.partner') },
      ]}
      identifier={'projects'}
      isLoading={isLoading}
    >
      <WizardStep>
        <p className="spartan-font">{t('Backoffice.Wizard.AddProject.steps.first')}</p>
        <FormTextInput
          placeholder={t('Backoffice.Wizard.AddProject.label.projectName')}
          type="text"
          id="projectName"
          name="projectName"
          disabled={readOnly}
        />
        <FormSelectMultipleAddresses
          readOnly={readOnly}
          editMode={editMode}
          setAddressIdsToDelete={setAddressIdsToDelete}
          setInitialvaluesChanged={setInitialvaluesChanged}
          countries={countries}
        />
        <CCol md="12">
          <CRow>
            <FormLabel name={t('Backoffice.users.table.columns.status')} />
          </CRow>
          <CRow>
            <CCol md="4">
              <FormCheckInput
                label={t('Backoffice.Wizard.AddUser.Label.userStatus.active')}
                value="active"
                type="radio"
                id="status"
                name="status"
                disabled={readOnly}
              />
            </CCol>
            <CCol md="4">
              <FormCheckInput
                label={t('Backoffice.Wizard.AddUser.Label.userStatus.inactive')}
                value="inactive"
                type="radio"
                id="status"
                name="status"
                disabled={readOnly}
              />
            </CCol>
          </CRow>
        </CCol>
      </WizardStep>
      <WizardStep>
        <FormSelectMultipleContacts
          readOnly={readOnly}
          partnerSelectionDisabled={partnerSelectionDisabled}
          setInitialvaluesChanged={setInitialvaluesChanged}
          partners={partners}
          branches={branches}
          editMode={editMode}
        />
      </WizardStep>
      <WizardStep>
        {partners.length ? (
          <FormSelectMultiplePartners
            partnerOptions={partners}
            readOnly={partnerSelectionDisabled ? partnerSelectionDisabled : readOnly}
            allBranches={branches}
            setInitialvaluesChanged={setInitialvaluesChanged}
          />
        ) : (
          <FormSelectMultipleBranches
            readOnly={partnerSelectionDisabled ? partnerSelectionDisabled : readOnly}
            allBranches={branches}
            setInitialvaluesChanged={setInitialvaluesChanged}
          />
        )}
      </WizardStep>
    </Wizard>
  )
}
