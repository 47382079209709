import { FieldArray, useFormikContext } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormAddAnotherButton } from './FormAddAnotherButton'
import { FormDeleteFieldButton } from './FormDeleteFieldButton'
import { FormSelect } from './FormSelect'
import uuid from 'react-uuid'
import { SearchableSelect } from './SearchableSelect'

export default function FormSelectMultiplePartners({
  partnerOptions,
  readOnly,
  allBranches,
  contactIndex,
}) {
  const { t } = useTranslation()
  partnerOptions = partnerOptions?.map((partner) => {
    return { label: partner.name, value: partner.id }
  })
  const {
    values: { contact_partners },
  } = useFormikContext()
  let partner = contact_partners[contactIndex] ?? ''
  const hasPartnerOptions = partnerOptions.map((pr) => pr.value).includes(partner)
  const getBranchOptions = (partnerId) => {
    let selectedPartner = partnerOptions.filter((partner) => partner.value == partnerId)[0]
    let branches = []
    if (!selectedPartner) {
      let parent_id = allBranches.find((branch) => branch.id == partnerId).parent_id
      selectedPartner = partnerOptions.find((partner) => partner.value == parent_id)
      branches = allBranches.filter((branch) => branch.parent_id === parent_id)
    } else {
      branches = allBranches.filter((branch) => branch.parent_id === partnerId)
    }
    branches = branches.map((branch) => {
      return { label: branch?.name, value: branch?.id }
    })
    branches.unshift({ label: selectedPartner.label + ' (HQ)', value: selectedPartner.value })
    return branches
  }

  return (
    <>
      {((!readOnly && partner === '') || hasPartnerOptions) && (
        <div className="w-2 h-2 position-relative">
          <p className="spartan-font">{t('Backoffice.branches.table.columns.partner')}</p>
          <SearchableSelect
            className="my-2 form-control-backoffice"
            placeholder={t('Backoffice.Wizard.label.partner')}
            name={`contact_partners.${contactIndex}`}
            id={`contact_partners.${contactIndex}`}
            options={partnerOptions}
            disabled={readOnly}
          />
          {partner && getBranchOptions(partner).length > 0 && (
            <SearchableSelect
              className="my-2 form-control-backoffice"
              placeholder={t('Backoffice.branches.table.columns.branch')}
              name={`contact_branches.${contactIndex}`}
              id={`contact_branches.${contactIndex}`}
              options={getBranchOptions(partner)}
              disabled={readOnly}
            />
          )}
        </div>
      )}
    </>
  )
}
