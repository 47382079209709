import { useEffect, useState } from 'react'
import { languages } from './languages'

export const SelectedLanguage = ({ lang }) => {
  const findLanguage = () => languages.find((language) => language.nameShort === lang)

  const [selectedLanguage, setSelectedLanguage] = useState(findLanguage())

  useEffect(() => {
    setSelectedLanguage(findLanguage())
  }, [lang])

  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
        <path
          fill="#fff"
          d="M11.543.332a11 11 0 1 0 0 22 11 11 0 0 0 0-22Zm8.956 6.947h-4.776c-.342-2.229-.973-4.232-1.876-5.505A9.871 9.871 0 0 1 20.5 7.279Zm-5.627 4.053c0 .967-.052 1.933-.156 2.895H8.347a26.882 26.882 0 0 1 0-5.79h6.369c.104.962.156 1.928.156 2.895ZM11.543 1.49c1.158 0 2.443 2.246 3.016 5.79H8.527c.573-3.544 1.858-5.79 3.016-5.79Zm-2.316.284C8.324 3.047 7.693 5.05 7.351 7.279H2.587a9.873 9.873 0 0 1 6.64-5.506Zm-7.526 9.558a9.954 9.954 0 0 1 .434-2.895h5.077a26.882 26.882 0 0 0 0 5.79H2.135a9.955 9.955 0 0 1-.434-2.895Zm.886 4.053h4.776c.342 2.229.973 4.232 1.876 5.505a9.871 9.871 0 0 1-6.652-5.505Zm8.956 5.79c-1.158 0-2.443-2.247-3.016-5.79h6.032c-.573 3.543-1.858 5.79-3.016 5.79Zm2.316-.285c.903-1.273 1.534-3.276 1.876-5.505h4.776a9.871 9.871 0 0 1-6.652 5.505Zm2.014-6.663a26.687 26.687 0 0 0 0-5.79h5.078a9.844 9.844 0 0 1 0 5.79h-5.078Z"
        />
      </svg>
      <span>{selectedLanguage && selectedLanguage.nameShort.toUpperCase()}</span>
      <svg
        className="arrow-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="6"
        fill="none"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M1.01.28a.368.368 0 0 1 .522 0l4.156 4.157L9.844.28a.369.369 0 1 1 .521.521L5.948 5.218a.368.368 0 0 1-.52 0L1.01.8a.368.368 0 0 1 0-.521Z"
          clipRule="evenodd"
        />
      </svg>
    </>
  )
}
