export const languages = [
  {
    nameShort: 'en',
    name: 'English',
  },
  {
    nameShort: 'de',
    name: 'German',
  },
  {
    nameShort: 'fr',
    name: 'French',
  },
  {
    nameShort: 'it',
    name: 'Italian',
  },
]
