import httpsV2 from '../../config/httpsV2'

// Create an empty Project Material View branchId, contextId, manufacturerId
export const createEmptyProjectMaterialView = (body) => {
  return httpsV2.post(`/public/pmv/`, body)
}

// Get Project Material View by ID
export const getProjectMaterialView = (pmvId, queryParam, signal = null) => {
  return httpsV2.get(`/public/pmv/${pmvId}${queryParam ? `?productState=${queryParam}` : ''}`, {
    signal,
  })
}

// Get Project Manufacturer
export const getProjectMaterialViewManufacturer = (id) => {
  return httpsV2.get(`/middleware/partners?id=${id}`)
}

// Get Project Name
export const getBuildingProject = (contextId) => {
  return httpsV2.get(`/middleware/building-projects?id=${contextId}`)
}

// Add product to Project Material View
export const addProductToProjectMaterialView = (data) => {
  return httpsV2.post('/public/pmv/products', data)
}

// Remove product from Project Material View
export const removeProductFromProjectMaterialView = (productId) => {
  return httpsV2.delete(`/public/pmv/products/${productId}`)
}

// Get all Project Material View Product Discount
export const getProductDiscountFromProjectMaterialView = (productId) => {
  return httpsV2.get(`/public/pmv/products/${productId}/discount`)
}

// Create a Project Material View Product Discount
export const createProductDiscountFromProjectMaterialView = (productId, data) => {
  return httpsV2.post(`/public/pmv/products/${productId}/discount`, data)
}

// Delete a Project Material View Product Discount
export const removeProductDiscountFromProjectMaterialView = (productId) => {
  return httpsV2.delete(`/public/pmv/products/${productId}/discount`)
}

// Get all Project Material View Product Adjusted Price
export const getProductAdjustedPriceFromProjectMaterialView = (productId) => {
  return httpsV2.get(`/public/pmv/products/${productId}/adjustedPrice`)
}

// Create a Project Material View Product Adjusted Price
export const createProductAdjustedPriceFromProjectMaterialView = (productId, data) => {
  return httpsV2.post(`/public/pmv/products/${productId}/adjustedPrice`, data)
}

// Delete a Project Material View Product Adjusted Price
export const removeProductAdjustedPriceFromProjectMaterialView = (productId) => {
  return httpsV2.delete(`/public/pmv/products/${productId}/adjustedPrice`)
}

// Get all Project Material View Product Material Surcharges
export const getProductMaterialSurchargesFromProjectMaterialView = (productId) => {
  return httpsV2.get(`/public/pmv/products/${productId}/surcharges/material`)
}

// Create a Project Material View Product Material Surcharges
export const createProductMaterialSurchargesFromProjectMaterialView = (productId, data) => {
  return httpsV2.post(`/public/pmv/products/${productId}/surcharges/material`, data)
}

// Update a Project Material View Product Material Surcharges
export const updateProductMaterialSurchargesFromProjectMaterialView = (productId, id, data) => {
  return httpsV2.patch(`/public/pmv/products/${productId}/surcharges/material/${id}`, data)
}

// Delete a Project Material View Product Material Surcharges
export const removeProductMaterialSurchargesFromProjectMaterialView = (productId, id) => {
  return httpsV2.delete(`/public/pmv/products/${productId}/surcharges/material/${id}`)
}

// Get all Project Material View Product Delivery Surcharges
export const getProductDeliverySurchargesFromProjectMaterialView = (productId) => {
  return httpsV2.get(`/public/pmv/products/${productId}/surcharges/delivery`)
}

// Create a Project Material View Product Delivery Surcharges
export const createProductDeliverySurchargesFromProjectMaterialView = (productId, data) => {
  return httpsV2.post(`/public/pmv/products/${productId}/surcharges/delivery`, data)
}

// Update a Project Material View Product Delivery Surcharges
export const updateProductDeliverySurchargesFromProjectMaterialView = (productId, id, data) => {
  return httpsV2.patch(`/public/pmv/products/${productId}/surcharges/delivery/${id}`, data)
}

// Delete a Project Material View Product Delivery Surcharges
export const removeProductDeliverySurchargesFromProjectMaterialView = (productId, id) => {
  return httpsV2.delete(`/public/pmv/products/${productId}/surcharges/delivery/${id}`)
}

// Get all Project Material View Product Delivery Costs
export const getProductDeliveryCostsFromProjectMaterialView = (productId) => {
  return httpsV2.get(`/public/pmv/products/${productId}/costs/delivery`)
}

// Create a Project Material View Product Delivery Costs
export const createProductDeliveryCostsFromProjectMaterialView = (productId, data) => {
  return httpsV2.post(`/public/pmv/products/${productId}/costs/delivery`, data)
}

// Update a Project Material View Product Delivery Costs
export const updateProductDeliveryCostsFromProjectMaterialView = (productId, id, data) => {
  return httpsV2.patch(`/public/pmv/products/${productId}/costs/delivery/${id}`, data)
}

// Delete a Project Material View Product Delivery Costs
export const removeProductDeliveryCostsFromProjectMaterialView = (productId, id) => {
  return httpsV2.delete(`/public/pmv/products/${productId}/costs/delivery/${id}`)
}

// Update product from Project Material View
export const updateProductInProjectMaterialView = (productId, data) => {
  return httpsV2.patch(`/public/pmv/products/${productId}`, data)
}
// Update product delivered quantity from Project Material View
export const updateDeliveredQuantityInProjectMaterialView = (productId, deliveryId, data) => {
  return httpsV2.patch(
    `/public/pmv/product/${productId}/delivery/${deliveryId}/deliveredQuantity`,
    data,
  )
}

// Get single product from Project Material View
export const getProductInProjectMaterialView = (productId) => {
  return httpsV2.get(`/public/pmv/products/${productId}`)
}

// Get deliveries of pmv grouped by datetime and address
export const getDeliveriesByState = (pmvId, state) => {
  return httpsV2.get(`/public/pmv/${pmvId}/deliveries?state=${state}`)
}

// request the given material positions.
export const requestMaterialPosition = (data) => {
  return httpsV2.post(`/public/pmv/products/request`, data)
}

// request the erp confirmation.
export const sendForErp = (data) => {
  return httpsV2.post(`/public/pmv/products/erp-confirmation`, data)
}

// request for buy now
export const requestBuyNow = (data) => {
  return httpsV2.post(`/public/pmv/products/buy-now`, data)
}

// request for holidays
export const requestHolidays = () => {
  return httpsV2.get('/middleware/holiday')
}
// request the given approved material positions with on demand deliveries.
export const requestOnDemandMaterialPosition = (data) => {
  return httpsV2.post(`/public/pmv/products/request-on-demand`, data)
}

// edit the given material positions.
export const editMaterialPosition = (data) => {
  return httpsV2.post(`/public/pmv/products/edit`, data)
}

export const sendForApproval = (data) => {
  return httpsV2.post(`/public/pmv/products/send-for-approval`, data)
}

export const rejectMaterialPosition = (data) => {
  return httpsV2.post(`/public/pmv/products/reject`, data)
}

export const approveMaterialPosition = (data) => {
  return httpsV2.post(`/public/pmv/products/approve`, data)
}

// getUsersPmvs.
export const getUsersPmvs = () => {
  return httpsV2.get(`/public/pmvs`)
}

//Get Project Material View messages, in batches of 1 .. 10
export const getPMVMessages = (pmvId, page) => {
  return httpsV2.get(`/public/pmv/${pmvId}/messages${page ? '?page=' + page : ''}`)
}

//Get a presinged url for file upload
export const getPMVMessagePresignedURL = (pmvId, filename) => {
  return httpsV2.get(`/public/pmv/${pmvId}/messages/url?filename=${filename}`)
}

//Post a new Project Material View message
export const postPMVMessage = (pmvId, pmvMessage) => {
  return httpsV2.post(`/public/pmv/${pmvId}/messages`, pmvMessage)
}

//Get PMV Product Comments
export const getPMVProductComments = (pmvProductId) => {
  return httpsV2.get(`/public/pmv/products/${pmvProductId}/comments`)
}

//Get a presinged url for PMV Product Comment file upload
export const getPMVProductCommentPresignedURL = (pmvProductId, filename) => {
  return httpsV2.get(`/public/pmv/products/${pmvProductId}/comments/url?filename=${filename}`)
}

//Post a new PMV Product Comment
export const postPMVProductComment = (pmvProductId, pmvProductComment) => {
  return httpsV2.post(`/public/pmv/products/${pmvProductId}/comments`, pmvProductComment)
}

//Get a presinged url for delivery confirmation file upload
export const getDeliveryConfirmationPresignedURL = (orderId, filename) => {
  return httpsV2.get(
    `/public/pmv/products/delivery-confirmation/${orderId}/url?filename=${filename}`,
  )
}

//Post a delivery confirmation
export const postDeliveryConfirmation = (pmvOrderId, orderConfirmation) => {
  return httpsV2.post(`/public/pmv/products/delivery-confirmation/${pmvOrderId}`, orderConfirmation)
}

// request the given material positions.
export const finalizeOrder = (data) => {
  return httpsV2.post(`/public/pmv/products/approve`, data)
}

// Request product changes (Delta)
export const syncProductChangesFromProjectMaterialView = (data) => {
  return httpsV2.post(`/public/pmv/products/changes`, data)
}

// Request product changes (Delta) For MF when he is editing a product
export const syncProductChangesForOfferValidityFromProjectMaterialView = (data) => {
  return httpsV2.post(`/public/pmv/products/changes/mf-blocked`, data)
}

// Update PMV
export const updatePMV = (pmvId, data) => {
  return httpsV2.patch(`/public/pmv/${pmvId}`, data)
}

//Get Invoices
export const getInvoices = (pmvId) => {
  let pmvIdQueryParameter = ''
  if (pmvId) {
    pmvIdQueryParameter = `?pmvId=${pmvId}`
  }
  return httpsV2.get(`/public/pmv/invoices${pmvIdQueryParameter}`)
}
