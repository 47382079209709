import React, { useEffect } from 'react'

export const Conditional = (props) => {
  const { condition } = props
  const { children } = props

  return <>{condition ? children : null}</>
}

export const If = ({ condition, ...props }) => {
  const { children } = props
  useEffect(() => {
    //console.log('If condition', condition)
  }, [condition])

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child) && child.type.name === 'Then') {
          return React.cloneElement(child, {
            condition: condition,
          })
        }
        if (React.isValidElement(child) && child.type.name === 'ElseIf') {
          return React.cloneElement(child, {
            condition: condition && child.condition,
          })
        }
        if (React.isValidElement(child) && child.type.name === 'Else') {
          return React.cloneElement(child, {
            condition: !condition,
          })
        }
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {})
        }
        return null
      })}
    </>
  )
}
export const Then = ({ condition, ...props }) => {
  const { children } = props

  useEffect(() => {
    //console.log('Then condition', condition)
  }, [condition])

  return <>{condition && children}</>
}

export const Else = ({ condition, ...props }) => {
  const { children } = props

  useEffect(() => {
    //console.log('Else condition', condition)
  }, [condition])
  return <>{condition && children}</>
}

export const ElseIf = ({ condition, ...props }) => {
  const { children } = props

  useEffect(() => {
    //console.log('ElseIf condition', condition)
  }, [condition])
  return <>{condition ? children : null}</>
}
