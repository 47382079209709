import * as Yup from 'yup'
import Wizard, { WizardStep } from 'src/components/forms/Wizard'
import { FormTextInput } from 'src/components/forms/FormTextInput'
import { FormSelectPartners } from 'src/components/forms/selects/FormSelectPartners'
import { createBranch, updateBranch } from 'src/services/requests/branches'
import { useTranslation } from 'react-i18next'
import { FormSelectCountriesDisabled } from 'src/components/forms/selects/FormSelectCountriesDisabled'
import { useState, useEffect, useRef, useContext } from 'react'
import { CCol, CRow } from '@coreui/react-pro'
import { FormLabel } from 'src/components/forms/FormLabel'
import { FormCheckInput } from 'src/components/forms/FormCheckInput'
import { UserDataContext } from 'src/contexts/userDataContext'
import { AuthContext, PERMISSIONS, VIEWS } from 'src/contexts/authContext'

export const BranchForm = ({
  editMode,
  rowData,
  fetchData,
  setOffcanvasVisible,
  setDeactivateOffcanvasVisible,
  setIsLoading,
  unsavedChangesOffcanvasVisible,
  setUnsavedChangesOffcanvasVisible,
  initialValuesChanged,
  setInitialvaluesChanged,
  readOnly,
  partners,
  setExistingStepNumber,
  setNewPartnerId,
}) => {
  const { setBranchUpdated } = useContext(UserDataContext)
  const { t } = useTranslation()
  const prepopulateData = editMode || readOnly
  const initialPartner = useRef(prepopulateData ? rowData.parent_id : '')

  const auth = useContext(AuthContext)
  const viewPermissions = PERMISSIONS[auth.role][VIEWS.BRANCHES].VIEW
  const editPermission = PERMISSIONS[auth.role][VIEWS.BRANCHES].EDIT

  const initialValues = {
    id: prepopulateData ? rowData?.id : undefined,
    partner: prepopulateData ? rowData.parent_id : '',
    name: prepopulateData ? rowData.name : '',
    branchCountry: prepopulateData ? rowData.address.country : 'CH',
    vat: prepopulateData ? rowData.legal_entity.vat_num : '',
    purpose: prepopulateData ? rowData.legal_entity.purpose : '',
    email: prepopulateData ? rowData.address.contact_email : '',
    country: prepopulateData ? rowData.country : 'CH',
    street: prepopulateData ? rowData.address.street : '',
    houseNumber: prepopulateData ? rowData.address.house_nr : '',
    city: prepopulateData ? rowData.address.city : '',
    postalCode: prepopulateData ? rowData.address.postal_code : '',
    domain: prepopulateData ? rowData.domain : '',
    longitude: prepopulateData ? rowData.address.lon : '',
    latitude: prepopulateData ? rowData.address.lat : '',
    primaryContactEmail: prepopulateData ? rowData.partner_config.contact_email : '',
    status: prepopulateData ? rowData?.status : 'active',
    HGCPartnerId: prepopulateData ? rowData?.partner_config?.hgc_partner_id : '',
    HGCPartnerCreditId: prepopulateData ? rowData?.partner_config?.hgc_partner_credit_id : '',
    invoice_email: prepopulateData ? rowData?.partner_config?.invoice_email : '',
    credit_email: prepopulateData ? rowData?.partner_config?.credit_email : '',
    receive_credit_emails: prepopulateData
      ? rowData?.partner_config?.receive_credit_emails?.toString()
      : true,
  }

  const handleSubmit = async (values) => {
    setIsLoading(true)
    const body = {
      type: 'building_contractor',
      name: values.name,
      country: values.country,
      legal_entity: {
        vat_num: values.vat,
        purpose: values.purpose || '',
      },
      address: {
        street: values.street,
        postal_code: values.postalCode,
        house_nr: editMode ? values.houseNumber || null : values.houseNumber || undefined,
        city: values.city,
        country: values.country,
        contact_email: values.email,
        lon: Number(values.longitude) || undefined,
        lat: Number(values.latitude) || undefined,
      },
      partner_config: {
        hgc_partner_id: values.HGCPartnerId,
        hgc_partner_credit_id: values.HGCPartnerCreditId,
        contact_email: values.primaryContactEmail,
        invoice_email: values.invoice_email,
        credit_email: values.credit_email,
        receive_credit_emails: values.receive_credit_emails === 'true' ? true : false,
      },
      parent_id: values.partner,
      domain: values.domain || null,
      status: values.status || 'active',
    }

    try {
      if (editMode) {
        await updateBranch(rowData.id, JSON.stringify(body))
      } else {
        setNewPartnerId(body.name)
        await createBranch(JSON.stringify(body))
      }
      await fetchData()
      setBranchUpdated((prev) => !prev)
      setOffcanvasVisible(false)
    } catch (error) {
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  const formSchema = {
    first: {
      step: 'first',
      fields: [
        'partner',
        'name',
        'country',
        'vat',
        'HGCPartnerId',
        'HGCPartnerCreditId',
        'primaryContactEmail',
        'purpose',
        'status',
        'invoice_email',
        'credit_email',
        'receive_credit_emails',
      ],
    },
    second: {
      step: 'second',
      fields: [
        'street',
        'houseNumber',
        'city',
        'postalCode',
        'country',
        'email',
        'longitude',
        'latitude',
      ],
    },
  }
  const validationSchema = Yup.object({
    partner: Yup.string().required(t('Yup.validation.error.required')),
    name: Yup.string().required(t('Yup.validation.error.required')),
    vat: Yup.string().required(t('Yup.validation.error.required')),
    HGCPartnerId: Yup.string().required(t('Yup.validation.error.required')),
    HGCPartnerCreditId: Yup.string().nullable(),
    primaryContactEmail: Yup.string()
      .required(t('Yup.validation.error.required'))
      .email(t('Yup.validation.error.invalid.email')),
    purpose: Yup.string().nullable(),
    domain: Yup.string().min(3, t('Yup.validation.error.domain.minLength')).nullable(),
    branchCountry: Yup.string()
      .required(t('Yup.validation.error.required'))
      .min(2, t('Yup.validation.error.country.length'))
      .max(2, t('Yup.validation.error.country.length')),
    email: Yup.string()
      .required(t('Yup.validation.error.required'))
      .email(t('Yup.validation.error.invalid.email')),
    country: Yup.string()
      .required(t('Yup.validation.error.required'))
      .min(2, t('Yup.validation.error.country.length'))
      .max(2, t('Yup.validation.error.country.length')),
    street: Yup.string().required(t('Yup.validation.error.required')),
    houseNumber: Yup.string().nullable(),
    city: Yup.string().required(t('Yup.validation.error.required')),
    postalCode: Yup.string().required(t('Yup.validation.error.required')),
    latitude: Yup.number().typeError(t('Yup.validation.error.invalid.lat')).nullable(),
    longitude: Yup.number().typeError(t('Yup.validation.error.invalid.lon')).nullable(),
    status: Yup.string(),
    invoice_email: Yup.string()
      .email(t('Yup.validation.error.invalid.email'))
      .required(t('Yup.validation.error.required')),
    credit_email: Yup.string()
      .email(t('Yup.validation.error.invalid.email'))
      .required(t('Yup.validation.error.required')),
    receive_credit_emails: Yup.boolean().required(),
  })

  return (
    <Wizard
      editMode={editMode}
      readOnly={readOnly}
      setOffcanvasVisible={setOffcanvasVisible}
      setDeactivateOffcanvasVisible={setDeactivateOffcanvasVisible}
      setInitialvaluesChanged={setInitialvaluesChanged}
      initialValuesChanged={initialValuesChanged}
      unsavedChangesOffcanvasVisible={unsavedChangesOffcanvasVisible}
      setUnsavedChangesOffcanvasVisible={setUnsavedChangesOffcanvasVisible}
      validationSchema={validationSchema}
      allFormSchema={{
        first: formSchema.first,
        second: formSchema.second,
      }}
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
      stepsProperties={[
        { id: 'first', label: t('Backoffice.Wizard.AddBranch.steps.first') },
        { id: 'second', label: t('Backoffice.Wizard.AddBranch.steps.second') },
      ]}
      setExistingStepNumber={setExistingStepNumber}
      identifier={'branches'}
    >
      <WizardStep>
        <p className="spartan-font">{t('Backoffice.Wizard.AddBranch.steps.first')}</p>
        {viewPermissions?.includes('partner') && partners.length > 0 && (
          <FormSelectPartners
            partners={partners}
            readOnly={!editPermission?.includes('partner') || readOnly}
            initialPartner={initialPartner}
            className="my-2 form-control-backoffice"
          />
        )}
        {viewPermissions?.includes('name') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.name')}
            type="text"
            id="name"
            name="name"
            disabled={!editPermission?.includes('name') || readOnly}
          />
        )}

        {viewPermissions?.includes('branchCountry') && (
          <FormSelectCountriesDisabled
            name="branchCountry"
            id="branchCountry"
            className="my-2 form-control-backoffice"
          />
        )}
        {viewPermissions?.includes('vat') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.vat')}
            type="text"
            id="vat"
            name="vat"
            disabled={!editPermission?.includes('vat') || readOnly}
          />
        )}
        {viewPermissions?.includes('HGCPartnerId') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.HGCPartnerID')}
            type="text"
            id="HGCPartnerId"
            name="HGCPartnerId"
            disabled={!editPermission?.includes('HGCPartnerId') || readOnly}
          />
        )}
        {viewPermissions?.includes('HGCPartnerCreditId') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.HGCPartnerCreditID')}
            type="text"
            id="HGCPartnerCreditId"
            name="HGCPartnerCreditId"
            disabled={!editPermission?.includes('HGCPartnerCreditId') || readOnly}
          />
        )}
        {viewPermissions?.includes('primaryContactEmail') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddPartner.PrimaryEmail')}
            type="email"
            id="primaryContactEmail"
            name="primaryContactEmail"
            disabled={!editPermission?.includes('primaryContactEmail') || readOnly}
          />
        )}
        {viewPermissions?.includes('purpose') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.purpose')}
            type="text"
            id="purpose"
            name="purpose"
            disabled={!editPermission?.includes('purpose') || readOnly}
          />
        )}
        {viewPermissions?.includes('domain') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.domain')}
            type="text"
            id="domain"
            name="domain"
            disabled={!editPermission?.includes('domain') || readOnly}
          />
        )}
        {viewPermissions?.includes('invoice_email') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.invoiceEmail')}
            type="text"
            id="invoice_email"
            name="invoice_email"
            disabled={!editPermission?.includes('invoice_email') || readOnly}
          />
        )}
        {viewPermissions?.includes('credit_email') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.creditEmail')}
            type="text"
            id="credit_email"
            name="credit_email"
            disabled={!editPermission?.includes('credit_email') || readOnly}
          />
        )}
        {viewPermissions?.includes('receive_credit_emails') && (
          <CCol md="12">
            <CRow>
              <FormLabel name={t('Backoffice.Wizard.AddPartner.receiveCreditEmails')} />
            </CRow>
            <CRow>
              <CCol md="4">
                <FormCheckInput
                  label={t('Global.Verbal.Positive')}
                  inline
                  defaultChecked
                  type="radio"
                  id="receiveCreditEmails"
                  name="receive_credit_emails"
                  value="true"
                  disabled={!editPermission?.includes('receive_credit_emails') || readOnly}
                />
              </CCol>

              <CCol md="4">
                <FormCheckInput
                  label={t('Global.Verbal.Negative')}
                  inline
                  type="radio"
                  id="receiveCreditEmails"
                  name="receive_credit_emails"
                  value="false"
                  disabled={!editPermission?.includes('receive_credit_emails') || readOnly}
                />
              </CCol>
            </CRow>
          </CCol>
        )}
        {viewPermissions?.includes('status') && (
          <CCol md="12">
            <CRow>
              <FormLabel name={t('Backoffice.users.table.columns.status')} />
            </CRow>
            <CRow>
              <CCol md="4">
                <FormCheckInput
                  label={t('Backoffice.Wizard.AddUser.Label.userStatus.active')}
                  value="active"
                  type="radio"
                  id="status"
                  name="status"
                  disabled={!editPermission?.includes('status') || readOnly}
                />
              </CCol>
              <CCol md="4">
                <FormCheckInput
                  label={t('Backoffice.Wizard.AddUser.Label.userStatus.inactive')}
                  value="inactive"
                  type="radio"
                  id="status"
                  name="status"
                  disabled={!editPermission?.includes('status') || readOnly}
                />
              </CCol>
            </CRow>
          </CCol>
        )}
      </WizardStep>
      <WizardStep>
        <p className="spartan-font">{t('Backoffice.Wizard.AddBranch.steps.second')}</p>
        {viewPermissions?.includes('email') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.email')}
            type="email"
            id="email"
            name="email"
            disabled={!editPermission?.includes('email') || readOnly}
          />
        )}
        {viewPermissions?.includes('country') && (
          <FormSelectCountriesDisabled
            id="country"
            name="country"
            className="my-2 form-control-backoffice"
          />
        )}
        {viewPermissions?.includes('street') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.street')}
            type="text"
            id="street"
            name="street"
            disabled={!editPermission?.includes('street') || readOnly}
          />
        )}
        {viewPermissions?.includes('houseNumber') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.houseNumber')}
            type="text"
            id="houseNumber"
            name="houseNumber"
            disabled={!editPermission?.includes('houseNumber') || readOnly}
          />
        )}
        {viewPermissions?.includes('postalCode') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.postalCode')}
            type="text"
            id="postalCode"
            name="postalCode"
            disabled={!editPermission?.includes('postalCode') || readOnly}
          />
        )}
        {viewPermissions?.includes('city') && (
          <FormTextInput
            placeholder={t('Backoffice.Wizard.AddBranch.label.city')}
            type="text"
            id="city"
            name="city"
            disabled={!editPermission?.includes('city') || readOnly}
          />
        )}

        {/* <FormTextInput
          placeholder={t('Backoffice.Wizard.AddProject.label.latitude')}
          type="text"
          id="latitude"
          name="latitude"
          disabled={readOnly}
        />
        <FormTextInput
          placeholder={t('Backoffice.Wizard.AddProject.label.longitude')}
          type="text"
          id="longitude"
          name="longitude"
          disabled={readOnly}
        /> */}
      </WizardStep>
    </Wizard>
  )
}
