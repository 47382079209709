import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CFormInput } from '@coreui/react-pro'
import PasswordChecklist from 'react-password-checklist'
import { validatePasswordSchema } from 'src/hooks/useAuthHooks'

export const Email = ({ emailIsValid, setEmail }) => {
  const { t } = useTranslation()
  return (
    <CFormInput
      type="email"
      placeholder={t('Component.Auth.Placeholder.Email')}
      onChange={(e) => {
        setEmail(e.target.value)
      }}
      className="light-background"
    />
  )
}

export const Password = ({ id = 'Password', defaultValue, label, isValid, setPassword }) => {
  const { t } = useTranslation()
  return (
    <CFormInput
      id="Password"
      type="password"
      placeholder={label ? label : t('Component.Auth.Placeholder.Password')}
      onInput={(e) => {
        setPassword(e.target.value)
      }}
      className="light-background"
      defaultValue={defaultValue}
    />
  )
}

export const PasswordWithValidation = ({
  password,
  setPassword,
  passwordAgain,
  setPasswordAgain,
}) => {
  const { t } = useTranslation()
  const [passwordType, setPasswordType] = useState('password')
  const [passwordAgainType, setPasswordAgainType] = useState('password')
  const [passwordChecklistVisible, setPasswordChecklistVisible] = useState(false)
  const [samePasswordChecklistVisible, setSamePasswordChecklistVisible] = useState(false)
  const [schemaValid, setSchemaValid] = useState(false)

  useEffect(() => {
    setSchemaValid(validatePasswordSchema(password))
  }, [password])

  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
      return
    }
    setPasswordType('password')
  }

  const togglePasswordAgain = () => {
    if (passwordAgainType === 'password') {
      setPasswordAgainType('text')
      return
    }
    setPasswordAgainType('password')
  }

  return (
    <>
      <div className="password-field-wrapper">
        <div className="position-relative">
          <CFormInput
            type={passwordType}
            placeholder={t('Authentication.SetUpPassword.Label.Password')}
            onInput={(e) => {
              setPassword(e.target.value)
            }}
            onFocus={(e) => {
              setPasswordChecklistVisible(true)
            }}
            onBlur={(e) => {
              setPasswordChecklistVisible(false)
            }}
            className="light-background"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="16"
            fill="none"
            className={`eye-hidden ${passwordType == 'text' ? '' : 'd-none'}`}
            onClick={togglePassword}
          >
            <path
              fill="#000"
              d="M16.82 12.076A17.21 17.21 0 0 0 20.146 8s-3.777-6.924-10.073-6.924a8.848 8.848 0 0 0-3.512.74l.969.97a7.484 7.484 0 0 1 2.543-.451 9.389 9.389 0 0 1 6.507 3.093A16.541 16.541 0 0 1 18.67 8a16.604 16.604 0 0 1-2.09 2.572c-.208.209-.424.414-.65.613l.89.892Z"
            />
            <path
              fill="#000"
              d="M14.227 9.481A4.406 4.406 0 0 0 8.59 3.846l1.036 1.036a3.148 3.148 0 0 1 3.562 3.562l1.035 1.035.004.002Zm-3.706 1.637 1.035 1.035a4.407 4.407 0 0 1-5.64-5.634l1.037 1.036a3.148 3.148 0 0 0 3.562 3.562l.006.001Z"
            />
            <path
              fill="#000"
              d="M4.218 4.815c-.227.201-.444.405-.652.613A16.536 16.536 0 0 0 1.476 8l.251.363a16.6 16.6 0 0 0 1.845 2.21 9.39 9.39 0 0 0 6.5 3.093 7.46 7.46 0 0 0 2.544-.454l.97.973a8.85 8.85 0 0 1-3.514.74C3.777 14.925 0 8 0 8a17.143 17.143 0 0 1 3.325-4.077l.892.893v-.001ZM17.186 16 2.077.891 2.97 0l15.108 15.109-.891.891Z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="14"
            fill="none"
            className={`eye-visible ${passwordType == 'password' ? '' : 'd-none'}`}
            onClick={togglePassword}
          >
            <path
              fill="#000"
              d="M19.818 6.813S16.102 0 9.91 0C3.716 0 0 6.813 0 6.813s3.716 6.812 9.91 6.812c6.192 0 9.908-6.813 9.908-6.813Zm-18.365 0a16.267 16.267 0 0 1 2.056-2.531 9.229 9.229 0 0 1 6.4-3.043 9.237 9.237 0 0 1 6.401 3.043c.769.772 1.458 1.62 2.056 2.53a8.192 8.192 0 0 1-.247.357 16.338 16.338 0 0 1-1.815 2.174 9.238 9.238 0 0 1-6.395 3.043 9.238 9.238 0 0 1-6.401-3.043 16.268 16.268 0 0 1-2.056-2.53Z"
            />
            <path
              fill="#000"
              d="M9.91 3.717a3.097 3.097 0 1 0 0 6.193 3.097 3.097 0 0 0 0-6.193ZM5.573 6.814a4.335 4.335 0 1 1 8.67 0 4.335 4.335 0 0 1-8.67 0Z"
            />
          </svg>
        </div>
        <PasswordChecklist
          className={`mt-3 mx-3 ${passwordChecklistVisible ? 'd-block' : 'd-none'}`}
          rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase']}
          minLength={8}
          value={password}
          valueAgain={passwordAgain}
          iconSize="16"
          messages={{
            minLength: t('Authorization.PasswordChecklist.MinLength'),
            specialChar: t('Authorization.PasswordChecklist.SpecialCharacter'),
            number: t('Authorization.PasswordChecklist.Number'),
            capital: t('Authorization.PasswordChecklist.Captial'),
            lowercase: t('Authorization.PasswordChecklist.Lowercase'),
          }}
        />
      </div>

      <div className="password-field-wrapper">
        <div className="position-relative">
          <CFormInput
            disabled={!schemaValid}
            className="light-background mt-4"
            type={passwordAgainType}
            placeholder={t('Authentication.SetUpPassword.Label.ConfirmPassword')}
            onInput={(e) => {
              setPasswordAgain(e.target.value)
            }}
            onFocus={(e) => {
              setSamePasswordChecklistVisible(true)
            }}
            onBlur={(e) => {
              setSamePasswordChecklistVisible(false)
            }}
            invalid={password !== passwordAgain}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="16"
            fill="none"
            className={`eye-hidden ${passwordAgainType == 'text' ? '' : 'd-none'}`}
            onClick={togglePasswordAgain}
          >
            <path
              fill="#000"
              d="M16.82 12.076A17.21 17.21 0 0 0 20.146 8s-3.777-6.924-10.073-6.924a8.848 8.848 0 0 0-3.512.74l.969.97a7.484 7.484 0 0 1 2.543-.451 9.389 9.389 0 0 1 6.507 3.093A16.541 16.541 0 0 1 18.67 8a16.604 16.604 0 0 1-2.09 2.572c-.208.209-.424.414-.65.613l.89.892Z"
            />
            <path
              fill="#000"
              d="M14.227 9.481A4.406 4.406 0 0 0 8.59 3.846l1.036 1.036a3.148 3.148 0 0 1 3.562 3.562l1.035 1.035.004.002Zm-3.706 1.637 1.035 1.035a4.407 4.407 0 0 1-5.64-5.634l1.037 1.036a3.148 3.148 0 0 0 3.562 3.562l.006.001Z"
            />
            <path
              fill="#000"
              d="M4.218 4.815c-.227.201-.444.405-.652.613A16.536 16.536 0 0 0 1.476 8l.251.363a16.6 16.6 0 0 0 1.845 2.21 9.39 9.39 0 0 0 6.5 3.093 7.46 7.46 0 0 0 2.544-.454l.97.973a8.85 8.85 0 0 1-3.514.74C3.777 14.925 0 8 0 8a17.143 17.143 0 0 1 3.325-4.077l.892.893v-.001ZM17.186 16 2.077.891 2.97 0l15.108 15.109-.891.891Z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="14"
            fill="none"
            className={`eye-visible ${passwordAgainType == 'password' ? '' : 'd-none'}`}
            onClick={togglePasswordAgain}
          >
            <path
              fill="#000"
              d="M19.818 6.813S16.102 0 9.91 0C3.716 0 0 6.813 0 6.813s3.716 6.812 9.91 6.812c6.192 0 9.908-6.813 9.908-6.813Zm-18.365 0a16.267 16.267 0 0 1 2.056-2.531 9.229 9.229 0 0 1 6.4-3.043 9.237 9.237 0 0 1 6.401 3.043c.769.772 1.458 1.62 2.056 2.53a8.192 8.192 0 0 1-.247.357 16.338 16.338 0 0 1-1.815 2.174 9.238 9.238 0 0 1-6.395 3.043 9.238 9.238 0 0 1-6.401-3.043 16.268 16.268 0 0 1-2.056-2.53Z"
            />
            <path
              fill="#000"
              d="M9.91 3.717a3.097 3.097 0 1 0 0 6.193 3.097 3.097 0 0 0 0-6.193ZM5.573 6.814a4.335 4.335 0 1 1 8.67 0 4.335 4.335 0 0 1-8.67 0Z"
            />
          </svg>
        </div>
        <PasswordChecklist
          className={`mt-3 mx-3 ${samePasswordChecklistVisible ? 'd-block' : 'd-none'}`}
          iconSize="16"
          rules={['match']}
          minLength={8}
          value={password}
          valueAgain={passwordAgain}
          messages={{
            match: t('Authorization.PasswordChecklist.Match'),
          }}
        />
      </div>
    </>
  )
}

export const Username = ({ defaultValue, setUsername }) => {
  const { t } = useTranslation()
  return (
    <CFormInput
      id="UserName"
      placeholder={t('Component.Auth.Placeholder.Email')}
      onInput={(e) => {
        setUsername(e.target.value)
      }}
      className="light-background"
      defaultValue={defaultValue}
    />
  )
}

export const Firstname = ({ firstnameIsValid, setFirstname }) => {
  const { t } = useTranslation()
  return (
    <CFormInput
      placeholder={t('Component.Auth.Placeholder.Firstname')}
      onChange={(e) => {
        setFirstname(e.target.value)
      }}
      className="light-background"
    />
  )
}

export const Lastname = ({ lastnameIsValid, setLastname }) => {
  const { t } = useTranslation()
  return (
    <CFormInput
      placeholder={t('Component.Auth.Placeholder.Lastname')}
      onChange={(e) => {
        setLastname(e.target.value)
      }}
      className="light-background"
    />
  )
}

export const Code = ({ codeIsValid, setCode }) => {
  const { t } = useTranslation()
  return (
    <CFormInput
      placeholder={t('Component.Auth.Placeholder.Code')}
      onChange={(e) => {
        setCode(e.target.value)
      }}
      className="light-background"
    />
  )
}
